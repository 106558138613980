import { useState } from "react";
import DashBackground from "../dash-background.jpeg";
import BusinessTransaction from "../Business-Transaction.jpeg";
import SteinStore from "stein-js-client";

const Landingpage = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const store = new SteinStore(
        'https://api.steinhq.com/v1/storages/6673684b4d11fd04f00aa190'
      );

      await store.append("Sheet1", [
        {
          email: email, // Use the actual email from your state
          timestamp: new Date().toISOString(),
        },
      ]);
      setSubmitted(true);
      setEmail("");
    } catch (error) {
      console.error("Error submitting email:", error);
      alert("failed to submit, please try again");
    }
  };

  return (
    <div>
      <section className=" min-h-96 relative flex flex-1 shrink-0 items-left justify-left overflow-hidden  bg-gray-100 py-16 shadow-lg md:py-20 xl:py-48">
        <img
          src={DashBackground}
          loading="lazy"
          alt="capital cartel"
          className="absolute inset-0 h-full w-full object-cover object-center"
        />

        <div className="absolute inset-0  bg-burnham-800 lg:bg-burnham-400  mix-blend-multiply"></div>

        <div className="relative flex flex-col items-center p-4 sm:max-w-xl">
          <h2 className="mb-6 text-center text-2xl font-bold text-burnham-500 sm:text-5xl md:mb-6 md:text-3xl">
            COMING SOON
          </h2>
          <p className="mb-4 text-center text-lg text-burnham-50 sm:text-xl md:mb-8">
            Showing you how to buy, fix, and flip online businesses.
          </p>
          <h1 className="mb-8 text-center text-4xl font-bold text-white sm:text-5xl md:mb-12 md:text-6xl">
            Welcome To <span className="text-burnham-500">Capital Cartel</span>
          </h1>
          {submitted ? (
            <p className="text-burnham-50">
              Thank you for joining the waitlist!
            </p>
          ) : (
            <form
              onSubmit={handleSubmit}
              className="flex w-full gap-2 md:max-w-md"
            >
              <input
                name="email"
                type="email"
                placeholder="Email"
                className="w-full flex-1 rounded border bg-gray-50 px-3 py-2 text-gray-800 placeholder-gray-500 outline-none ring-burnham-300 transition duration-100 focus:ring"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <button
                type="submit"
                className="inline-block rounded bg-greenpea-600 px-8 py-2 text-center text-sm font-semibold text-white outline-none ring-burnham-300 transition duration-100 hover:bg-burnham-800 focus-visible:ring active:bg-indigo-700 md:text-base"
              >
                Join Waitlist
              </button>
            </form>
          )}
        </div>
      </section>

      <section className="p-6 flex flex-col justify-between gap-6 sm:gap-10 md:gap-16 md:flex-row lg:flex-row">
        <div className="flex flex-col items-center justify-center sm:text-center lg:items-start lg:py-12 lg:text-left xl:w-5/12 xl:py-24">
          <h2 className="mb-8 text-4xl font-bold text-burnham-950 sm:text-5xl md:mb-12 md:text-5xl">
            We show you how to buy, fix and flip businesses.
          </h2>

          <p className="mb-8 leading-relaxed text-gray-500 md:mb-12 lg:w-4/5 xl:text-xl">
            Walking people through the acquisition of a business, Setting up
            systems and automations for it to grow in MRR and active users,
            ensuring you achieve success and profitability.
          </p>
        </div>

        <div className="h-58 overflow-hidden rounded-lg bg-gray-100 md:w-7/12  lg:h-auto xl:w-7/12">
          <img
            src={BusinessTransaction}
            loading="lazy"
            alt="capital cartel"
            className="h-full w-full object-cover object-center"
          />
        </div>
      </section>
      <div className="custom-shape-divider-bottom-1718819907">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <div className="bg-burnham-600 py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-lg px-4 md:px-8">
          <div className="mb-10 md:mb-16">
            <h2 className="mb-4 text-center text-2xl font-bold text-burnham-50 md:mb-6 lg:text-3xl">
              Buying A Business vs Starting a business
            </h2>
          </div>

          <div className="flex flex-wrap items-center justify-center gap-4 sm:gap-0">
            <div className="w-full rounded-lg bg-gradient-to-tr from-burnham-800 to-burnham-500 p-6 shadow-xl sm:w-1/2 sm:p-8">
              <div className="mb-4 flex flex-col items-start justify-between gap-4 lg:flex-row">
                <div>
                  <h3 className="text-2xl font-semibold text-white sm:text-3xl">
                    Buying A Business
                  </h3>
                </div>

                <span className="order-first inline-block rounded-full bg-indigo-200 bg-opacity-50 px-3 py-1 text-xs font-semibold uppercase tracking-wider text-white lg:order-none">
                  Best value
                </span>
              </div>

              <ul className="mb-6 space-y-2 text-burnham-50">
                <li className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 shrink-0"
                    viewBox="0 0 20 20"
                    fill="#22c55e"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      cliprulecliprule="evenodd"
                    />
                  </svg>

                  <span>Immediate Cash Flow</span>
                </li>

                <li className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 shrink-0"
                    viewBox="0 0 20 20"
                    fill="#22c55e"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <span>Systems in Place</span>
                </li>

                <li className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 shrink-0"
                    viewBox="0 0 20 20"
                    fill="#22c55e"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <span>Proof of Concept</span>
                </li>

                <li className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 shrink-0"
                    viewBox="0 0 20 20"
                    fill="#22c55e"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <span>Established Customer Base</span>
                </li>

                <li className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 shrink-0"
                    viewBox="0 0 20 20"
                    fill="#22c55e"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <span>Reduced Risk</span>
                </li>
                <li className="flex items-center gap-1.5"> </li>
                <li className="flex items-center gap-1.5"> </li>
                <li className="flex items-center gap-1.5"> </li>
              </ul>
            </div>

            <div className="w-full rounded-lg bg-burnham-900 p-6 sm:w-1/2 sm:rounded-l-none sm:p-8 lg:w-1/3">
              <div className="mb-4">
                <h3 className="text-2xl font-semibold text-burnham-50 sm:text-3xl">
                  Starting a business
                </h3>
              </div>

              <ul className="mb-6 space-y-2 text-gray-300">
                <li className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 shrink-0"
                    viewBox="0 0 20 20"
                    fill="#ef4444"
                  >
                    <circle cx="10" cy="10" r="8" />
                  </svg>

                  <span>High Initial Investment</span>
                </li>

                <li className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 shrink-0"
                    viewBox="0 0 20 20"
                    fill="#ef4444"
                  >
                    <circle cx="10" cy="10" r="8" />
                  </svg>

                  <span>Building Systems from Scratch</span>
                </li>

                <li className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 shrink-0"
                    viewBox="0 0 20 20"
                    fill="#ef4444"
                  >
                    <circle cx="10" cy="10" r="8" />
                  </svg>

                  <span>No Proof of Concept</span>
                </li>
                <li className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 shrink-0"
                    viewBox="0 0 20 20"
                    fill="#ef4444"
                  >
                    <circle cx="10" cy="10" r="8" />
                  </svg>

                  <span>No Product Market Fit</span>
                </li>
                <li className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 shrink-0"
                    viewBox="0 0 20 20"
                    fill="#ef4444"
                  >
                    <circle cx="10" cy="10" r="8" />
                  </svg>

                  <span>HIGH Risk of Failure</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-tl from-burnham-600 to-burnham-600 py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-lg px-4 md:px-8">
          <div className="flex flex-col items-center shadow-lg justify-between gap-4 rounded-lg bg-big-stone-700 p-4 sm:flex-row md:p-8">
            <div>
              <h2 className="text-xl text-left font-bold text-burnham-500 md:text-2xl">
                Join The Wait List Now
              </h2>
              <p className="text-gray-50 text-left">
                Showing you how to buy, fix, and flip online businesses.
              </p>
            </div>
            {submitted ? (
              <p className="text-burnham-50">
                Thank you for joining the waitlist!
              </p>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="flex w-full gap-2 md:max-w-md"
              >
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="w-full flex-1 rounded border bg-gray-50 px-3 py-2 text-gray-800 placeholder-gray-500 outline-none ring-burnham-300 transition duration-100 focus:ring"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <button
                  type="submit"
                  className="inline-block rounded bg-greenpea-600 px-8 py-2 text-center text-sm font-semibold text-white outline-none ring-burnham-300 transition duration-100 hover:bg-burnham-800 focus-visible:ring active:bg-indigo-700 md:text-base"
                >
                  Join Waitlist
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
